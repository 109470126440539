import {
  Box,
  Checkbox,
  ColorInput,
  ColorPicker,
  Slider,
  Text,
} from "@mantine/core";
import { Html, Select, TransformControls } from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import {
  Mesh,
  BufferGeometry,
  Material,
  Matrix4,
  Group,
  Object3D,
  MeshStandardMaterial,
} from "three";
import useStore from "../app/store";

import "../App.css";

interface WallProps {
  transformation: Matrix4;
  id: string;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
  isSelected: boolean;
}

export default function Wall({
  transformation,
  id,
  onClick,
  isSelected,
}: WallProps) {
  const selectedTool = useStore((state) => state.selectedTool);

  const ref = useRef<Mesh<BufferGeometry, Material | Material[]>>(null);
  const materialRef = useRef<MeshStandardMaterial>(null);

  const colorRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.applyMatrix4(transformation);
    }
  }, []);

  useEffect(() => {
    if (!materialRef.current) return;
    console.log("test");
    
  }, [materialRef.current]);

  function MaterialMenu() {
    const [isTransparent, setIsTransparent] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<number>(100);
    const [color, setColor] = useState<string>();

    useEffect(() => {
      if (!materialRef.current) return;
      setIsTransparent(materialRef.current.transparent);
      setOpacity(materialRef.current.opacity * 100);
      setColor("#"+materialRef.current.color.getHexString());
    }, [materialRef.current]);

    return (
      <Box
        sx={{
          background: "#181c20",
          border: "1px solid #292d39",
          borderRadius: "10px",
          overflow: "hidden",
          width: "18rem",
          height: "8rem",
          display: "flex",
          flexDirection: "row",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-around",
            padding: "5px",
          }}
        >
          <Text>Transparent</Text>
          <Text>Opacity</Text>
          <Text>Color</Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-around",
          }}
        >
          <Checkbox
            checked={isTransparent}
            onChange={(event) => {
              if (!materialRef.current) return;
              materialRef.current.transparent = event.currentTarget.checked;
              console.log(materialRef.current.transparent,materialRef.current.opacity);

              setIsTransparent(event.currentTarget.checked);
            }}
          />
          <Slider
            value={opacity}
            onChange={(value) => {
              if (!materialRef.current) return;
              materialRef.current.opacity = value / 100;

              setOpacity(value);
            }}
          />
          <ColorInput
            sx={{ body: { zIndex: 100 } }}
            value={color}
            onChange={(value) => {
              materialRef.current?.color.set(value);
              setColor(value);
            }}
          />
        </Box>
      </Box>
    );
  }
  // function MaterialMenu() {
  //   return (
  //     <Box
  //       sx={{
  //         background: "#181c20",
  //         border: "1px solid #292d39",
  //         borderRadius: "10px 10px 0 0",
  //         height: "16rem",
  //         overflow: "auto",
  //         width: "16rem",
  //         display: "flex",
  //         flexDirection: "column",
  //       }}
  //     >
  //       <Checkbox
  //         label={"Transparency"}
  //         labelPosition="left"
  //         color={"white"}
  //         sx={{ label: { color: "white" },inner:{justifyItems:"space-around"} }}
  //       />
  //       <Box sx={{ display: "flex", flexDirection: "row", width: "100%",justifyContent:"space-around" }}>
  //         <Text color={"white"}>Color </Text>
  //         <ColorInput
  //           sx={{ width: "5rem" }}
  //           value={colorRef.current}
  //           onChange={(color) => materialRef.current?.color.set(color)}
  //         />
  //       </Box>
  //     </Box>
  //   );
  // }

  // useFrame(() => {
  //   if (colorRef.current) materialRef.current?.color.set(colorRef.current);
  // });

  return (
    <group>
      {isSelected && (
        <Html
          style={{ position: "fixed", top: "5px", right: "5px" }}
          // wrapperClass="wallMaterialPanel"
          calculatePosition={(el, camera, size) => []}
          zIndexRange={[0, 0]}
        >
          <MaterialMenu />
        </Html>
      )}
      <mesh ref={ref} visible={true} userData={{ id: id }} onClick={onClick}>
        <boxGeometry />
        <meshStandardMaterial ref={materialRef} color={"gray"}  transparent/>
      </mesh>
    </group>
  );
}
