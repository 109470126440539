import { Layers } from "three";

const render_raycast = new Layers();

render_raycast.set(0);

const render = new Layers();

render.set(1);

const raycast = new Layers();

raycast.set(2);

const hidden = new Layers();

hidden.set(3);


export default {
  /**
   * Default Layer. Both renders 3D object and honors Raycasting
   */
  render_raycast,
  /**
   * Only renders 3D object and doesn't honor Raycasting
   */
  render,
  /**
   * DO NOT USE!
   * Only honors raycasting and doesn't render 3D object. For testing issues
   */
  raycast,
  /**
   * Doesn't render 3D object and doesn't honor Raycasting.
   */
  hidden,
};
