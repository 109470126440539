import {
  OrbitControls,
  OrthographicCamera,
  PerspectiveCamera,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Group, Layers, Raycaster } from "three";
import useStore from "../app/store";
import DimensionButtons from "../components2D/DimensionButtons";
import ToolButtons from "../components2D/EditorToolButtons";
import ExportButton from "../components2D/ExportButton";
import FurnitureButtons from "../components2D/FurnitureButtons";
import FurnitureDrawer from "../components2D/FurnitureDrawer";
import { CameraControls } from "../components3D/camera-controls";
import Cameras from "../components3D/Cameras";
import Scene from "../components3D/Scene";

export default function Editor() {
  // const [isOrtho, setIsOrtho] = useState<boolean>(false);
  const displayDimension = useStore((state) => state.displayDimension);
  const setDisplayDimension = useStore((state) => state.setDisplayDimension);
  const sceneRef = useRef<Group>(null);

  async function handleDoubleClick() {
    setDisplayDimension(displayDimension === "3D" ? "2D" : "3D");
  }
  const raycaster = new Raycaster();
  raycaster.layers.enable(2);

  return (
    <div className="App">
      <Canvas
        raycaster={raycaster}
        // onDoubleClick={() => handleDoubleClick()}
      >
        <Cameras />
        <Scene ref={sceneRef} />
      </Canvas>
      <ToolButtons />
      <DimensionButtons />
      <FurnitureButtons />
      <FurnitureDrawer />
      <ExportButton sceneRef={sceneRef} />
    </div>
  );
}
