import { memo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useStore from "../app/store";

const RequireConfig = () => {
  const location = useLocation();
  const { config } = useStore();

  return config ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireConfig;
