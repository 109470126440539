import { Box, Button } from "@mantine/core";
import { MouseEventHandler } from "react";
import useStore from "../app/store";

export default function DimensionButtons() {
  const setDisplayDimension = useStore((state) => state.setDisplayDimension);

  function handle2DClick() {
    setDisplayDimension("2D");
  }
  function handle3DClick() {
    setDisplayDimension("3D");
  }
  return (
    <Box
      sx={{
        position: "fixed",
        top: "10px",
        left: "45%",
        display: "flex",
        flexDirection: "row",
        padding: "5px",
      }}
    >
      <Button
        onClick={() => handle2DClick()}
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
      >
        2D
      </Button>
      <Button
        onClick={() => handle3DClick()}
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
      >
        3D
      </Button>
    </Box>
  );
}
