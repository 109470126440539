import { Box, Button, Image } from "@mantine/core";
import useStore from "../app/store";
import drawWallIcon from "../assets/images/drawWall.png";
// import selectIcon from "../assets/images/selectIcon.png";
import selectIcon from "../assets/images/selection.png";
import normal from "../assets/images/normal.png";

export default function EditorButtons() {
  const setSelectedTool = useStore((state) => state.setSelectedTool);
  const setIsCameraRotationLocked = useStore(
    (state) => state.setIsCameraRotationLocked
  );

  const handleDrawWallClick = () => {
    setSelectedTool("drawWall");
    setIsCameraRotationLocked(true);
  };
  const handleSelectClick = () => {
    setSelectedTool("select");
    setIsCameraRotationLocked(false);
  };
  /*   const handleNormalClick = () => {
    setSelectedTool("move");
    setIsCameraRotationLocked(false);
  }; */

  return (
    <Box
      sx={{
        position: "fixed",
        left: "10px",
        top: "40%",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleSelectClick()}
      >
        <Image src={normal} width={25} />
      </Button>
      {/*       <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleSelectClick()}
      >
        <Image src={selectIcon} width={25} />
      </Button> */}
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleDrawWallClick()}
      >
        <Image src={drawWallIcon} width={25} />
      </Button>
    </Box>
  );
}
