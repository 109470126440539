import { MathUtils, RepeatWrapping } from "three";
import Layers from "../constants/Layers";
import GroundImage from "../assets/images/ground.jpg";
import { useTexture } from "@react-three/drei";

export default function Ground() {
  let tex = useTexture(GroundImage);
  tex.wrapS = RepeatWrapping;
  tex.wrapT = RepeatWrapping;
  tex.repeat.set(4, 4);
  return (
    <group>
      <mesh
        rotation={[-MathUtils.degToRad(90), 0, 0]}
        position={[0, -0.1, 0]}
        layers={Layers.render}
      >
        <planeBufferGeometry args={[100, 100, 100, 100]} />
        <meshStandardMaterial map={tex} />
      </mesh>
    </group>
  );
}
