import { Box, Card, Image, LoadingOverlay, Text } from "@mantine/core";
import { Suspense } from "react";
import useStore from "../app/store";

interface Props {
  id: string;
  name: string;
  type: string;
}

export default function FurnitureCard({ id, name, type }: Props) {
  const isFurnitureDrawerOpen = useStore(
    (state) => state.isFurnitureDrawerOpen
  );

  const setIsFurnitureDrawerOpen = useStore(
    (state) => state.setIsFurnitureDrawerOpen
  );

  const selectedFurnitureId = useStore((state) => state.selectedFurnitureId);
  const setSelectedFurnitureId = useStore(
    (state) => state.setSelectedFurnitureId
  );
  const setSelectedTool = useStore((state) => state.setSelectedTool);

  const setIsCameraRotationLocked = useStore(
    (state) => state.setIsCameraRotationLocked
  );
  const setIsCameraDollyLocked = useStore(
    (state) => state.setIsCameraDollyLocked
  );

  function handleClick() {
    setIsFurnitureDrawerOpen(false);
    setSelectedFurnitureId(id);
    setSelectedTool("drawFurniture");
    setIsCameraRotationLocked(true);
    setIsCameraDollyLocked(true);
    console.log(id);
  }
  return (
    <Card
      onClick={() => handleClick()}
      sx={{
        width: "300px",
        // padding:"20px",
        marginBottom: "15px",
      }}
    >
      <Card.Section>
        <Suspense fallback={<LoadingOverlay visible />}>
          <Image src={`/Furniture/${type}/${id}.png`} />
        </Suspense>
      </Card.Section>
      <Text>{name}</Text>
    </Card>
  );
}
