import {
  OrbitControls,
  OrthographicCamera,
  PerspectiveCamera,
  useCursor,
} from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import {
  BufferGeometry,
  Material,
  Matrix4,
  Mesh,
  Layers,
  Quaternion,
  Vector3,
  Group,
} from "three";
import useStore from "../app/store";
import { CameraControls } from "./camera-controls";
import Floor from "./Floor";
import Furnitures from "./Furnitures";
import Ground from "./Ground";
import Walls from "./Walls";

export const Scene = forwardRef<Group>((_,ref) => {
  const selectedTool = useStore((state) => state.selectedTool);
  const mousePositionRef = useRef<Vector3>(new Vector3());

  const [lastClickedWallPoint, setlastClickedWallPoint] =
    useState<Vector3 | null>(null);

  const [lastClickedFurniturePoint, setlastClickedFurniturePoint] =
    useState<Vector3 | null>(null);

  useCursor(selectedTool === "select", "pointer", "auto");
  useCursor(selectedTool === "drawWall", "crosshair", "auto");
  // useCursor(selectedTool === "move", "move", "auto");

  useEffect(() => {
    if (selectedTool !== "drawWall") {
      setlastClickedWallPoint(null);
    }
    if (selectedTool !== "drawFurniture") {
      setlastClickedFurniturePoint(null);
    }
  }, [selectedTool]);

  const handleClickFloor = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      if (selectedTool == "drawWall") {
        setlastClickedWallPoint(event.point);
      }
      if (selectedTool == "drawFurniture") {
        setlastClickedFurniturePoint(event.point);
      }
    },
    [selectedTool]
  );

  const handlePointerMove = useCallback((event: ThreeEvent<PointerEvent>) => {
    mousePositionRef.current = event.point;
  }, []);

  /*   const pointerClick = useCallback((event: MouseEvent) => {
    if (selectedTool == "drawWall") {
      setlastWallPoint(event.point);
    }
  }, [selectedTool]);
 */
  return (
    <group ref={ref}>
      <ambientLight intensity={0.1} />
      <pointLight intensity={1} position={[0, 20, 0]} />
      <Floor
        handleClickFloor={handleClickFloor}
        handlePointerMove={handlePointerMove}
      />
      <Walls
        mousePosition={mousePositionRef}
        lastWallPoint={lastClickedWallPoint}
      />
      <Furnitures
        mousePosition={mousePositionRef}
        lastFurniturePoint={lastClickedFurniturePoint}
      />
      <Ground />
    </group>
  );
});

export default Scene;