import { GroupProps, ThreeEvent } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { Group, Matrix4 } from "three";

import Chair_1 from "../models/Chair_1";
import Chair_2 from "../models/Chair_2";
import Table_1 from "../models/Table_1";
import Table_2 from "../models/Table_2";

interface Props {
  transformation: Matrix4;
  id: string;
  type: string;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
}

export default function Furniture({
  transformation,
  id,
  type,
  onClick,
}: Props) {
  const ref = useRef<Group>(null);

  const models = {
    chair_1: Chair_1,
    chair_2: Chair_2,
    table_1: Table_1,
    table_2: Table_2,
  };

  function placeHolderMesh({ ...props }: GroupProps) {
    return (
      <group visible={false}>
        <mesh>
          <boxGeometry />
          <meshBasicMaterial />
        </mesh>
      </group>
    );
  }

  let Furniture = placeHolderMesh;

  Furniture = models[type as keyof typeof models];

  useEffect(() => {
    ref.current?.applyMatrix4(transformation);
  }, []);

  return (
    <group ref={ref} onClick={onClick}>
      <Furniture userData={{ id: id }} />
    </group>
  );
}
