import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Config from "../pages/Config";
import Editor from "../pages/Editor";
import RequireConfig from "./RequireConfig";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Config />} />
        <Route element={<RequireConfig />}>
          <Route path="*" element={<Config />} />
          <Route path="/editor" element={<Editor />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
