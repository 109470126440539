import { ThreeEvent, useFrame } from "@react-three/fiber";
import { Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Mesh,
  BufferGeometry,
  Material,
  Matrix4,
  Quaternion,
  Vector3,
  Object3D,
  Group,
  MathUtils,
} from "three";
import useStore from "../app/store";
import Wall from "./Wall";
import { v4 as uuid } from "uuid";
import { Html, Select, TransformControls } from "@react-three/drei";
import ObjectToolButtons from "../components2D/ObjectToolButtons";

interface Props {
  lastWallPoint: Vector3 | null;
  mousePosition: React.MutableRefObject<Vector3>;
}

let rotationAxis = new Vector3(0, 1, 0);

let snapAngles = [
  new Quaternion().setFromAxisAngle(rotationAxis, 0),
  new Quaternion().setFromAxisAngle(rotationAxis, Math.PI / 2),
  new Quaternion().setFromAxisAngle(rotationAxis, Math.PI),
  new Quaternion().setFromAxisAngle(rotationAxis, (Math.PI * 3) / 2),
];

export default function Walls({ lastWallPoint, mousePosition }: Props) {
  const bounds = useStore((state) => state.bounds);
  const layoutImage = useStore((state) => state.layoutImage);
  const selectedTool = useStore((state) => state.selectedTool);
  const setIsCameraRotationLocked = useStore(
    (state) => state.setIsCameraRotationLocked
  );
  const isCameraRotationLocked = useStore(
    (state) => state.isCameraRotationLocked
  );
  const setSelectedTool = useStore((state) => state.setSelectedTool);
  const idRef = useRef<number>(0);

  const wallRef = useRef<Mesh<BufferGeometry, Material | Material[]> | null>(
    null
  );
  const toolRef = useRef<Group>(null);

  const previousPoint = useRef<Vector3 | null>();

  const [allWalls, setAllWalls] = useState<
    { transformation: Matrix4; id: string }[] | []
  >([]);
  const [selected, setSelected] = useState<Object3D>();
  const [objectTool, setObjectTool] = useState<
    "translate" | "rotate" | "scale"
  >("translate");

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setSelectedTool("select");
      setIsCameraRotationLocked(false);
      setSelected(undefined);
    }
  }, []);
  const rightClickFunction = useCallback((event: MouseEvent) => {
    if (event.button === 2) {
      setSelectedTool("select");

      setIsCameraRotationLocked(false);
      setSelected(undefined);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", (e) => rightClickFunction(e), false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener(
        "mousedown",
        (e) => rightClickFunction(e),
        false
      );
    };
  }, []);

  useEffect(() => {
    previousPoint.current = lastWallPoint;
  }, []);

  useEffect(() => {
    if (previousPoint.current !== null && lastWallPoint !== null) {
      setAllWalls((all) => {
        const tempAll = [...all];
        if (wallRef.current && previousPoint.current) {
          // let id = uuid();
          let id = idRef.current;
          idRef.current += 1;
          tempAll.push({
            transformation: wallRef.current.matrix.clone(),
            id: id.toString(),
          });
        }
        return tempAll;
      });
      previousPoint.current = lastWallPoint;
    } else {
      previousPoint.current = lastWallPoint;
    }
  }, [lastWallPoint]);

  useEffect(() => {
    if (selectedTool !== "select") {
      setSelected(undefined);
    }
  }, [selectedTool]);

  let rotationMatrix = new Matrix4();
  let targetQuaternion = new Quaternion();
  let up = new Vector3(0, 1, 0);

  useFrame(() => {
    if (selectedTool === "drawWall" && lastWallPoint && wallRef.current) {
      wallRef.current.visible = true;
      let midPosition = lastWallPoint
        .clone()
        .add(mousePosition.current)
        .divideScalar(2);
      wallRef.current.position.set(
        midPosition.x,
        midPosition.y + bounds.wallHeight / 2,
        midPosition.z
      );

      rotationMatrix.lookAt(lastWallPoint, mousePosition.current, up);
      targetQuaternion.setFromRotationMatrix(rotationMatrix);

      for (let snap of snapAngles) {
        if (MathUtils.radToDeg(targetQuaternion.angleTo(snap)) < 5) {
          targetQuaternion.copy(snap);
        }
      }

      wallRef.current.setRotationFromQuaternion(targetQuaternion);

      let length = lastWallPoint.clone().sub(mousePosition.current).length();

      wallRef.current.scale.set(0.1, bounds.wallHeight, length);
    } else if (wallRef.current) {
      wallRef.current.visible = false;
    }
  });

  useFrame(() => {
    if (!toolRef.current || !selected) return;
    let toolPosition = selected.position;
    // console.log(toolPosition);

    toolRef.current.position.set(
      toolPosition.x,
      toolPosition.y,
      toolPosition.z
    );
  });

  function handleObjectToolClick(type: "translate" | "rotate" | "scale") {
    setObjectTool(type);
  }
  function handleDeleteClick() {
    if (!selected) return;
    const temp = [...allWalls];
    const index = temp.findIndex((e) => e.id === selected.userData.id);
    temp.splice(index, 1);

    setSelected(undefined);
    setAllWalls(temp);
  }

  const controls = useMemo(
    () => (
      <TransformControls
        mode={objectTool}
        object={selected}
        onMouseDown={(e) => {
          setIsCameraRotationLocked(true);
        }}
        onMouseUp={() => {
          setIsCameraRotationLocked(false);
        }}
      >
        <group ref={toolRef}>
          <Html style={{ transform: "translate(-11rem,5rem)" }}>
            <ObjectToolButtons
              handleObjectToolClick={handleObjectToolClick}
              handleDeleteClick={handleDeleteClick}
            />
          </Html>
        </group>
      </TransformControls>
    ),
    [selected, objectTool]
  );

  const selectWall = (e: ThreeEvent<MouseEvent>) => {
    if (selectedTool === "select") {
      setSelected(e.intersections[0].object);
    }
  };

  return (
    <group>
      <mesh ref={wallRef} visible={false}>
        <boxGeometry />
        <meshStandardMaterial color={"orange"} />
      </mesh>
      {selected && controls}
      {allWalls.map(({ transformation, id }, index) => {
        return (
          <group key={id}>
            <Wall
              transformation={transformation}
              id={id}
              onClick={selectWall}
              isSelected={selected?.userData.id === id}
            />
            ;
          </group>
        );
      })}
    </group>
  );
}
