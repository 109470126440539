import { Box, Button } from "@mantine/core";
import { RefObject } from "react";
import { Group } from "three";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";

interface Props {
  sceneRef: RefObject<Group>;
}

const link = document.createElement("a");
link.style.display = "none";
document.body.appendChild(link); // Firefox workaround, see #6594

function save(blob: Blob, filename: string) {
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  // URL.revokeObjectURL( url ); breaks Firefox...
}

function saveArrayBuffer(buffer: any, filename: string) {
  save(new Blob([buffer], { type: "application/octet-stream" }), filename);
}

export default function ExportButton({ sceneRef }: Props) {
  function handleClick() {
    if (!sceneRef.current) return;

    const exporter = new GLTFExporter();
    exporter.parse(
      sceneRef.current,
      (glb) => {
        saveArrayBuffer(glb, "scene.glb");
      },
      (error) => {
        console.log(error);
      },
      { binary: true }
    );
  }
  return (
    <Button
      sx={{
        position: "fixed",
        right: "10px",
        bottom: "5%",
      }}
      color="gray"
      onClick={handleClick}
    >
      Export Model
    </Button>
  );
}
