import { OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import { useRef, useEffect, memo, useMemo, useState } from "react";
import { Raycaster, Layers } from "three";
import useStore from "../app/store";
import { CameraControls } from "./camera-controls";

function Cameras() {
  const displayDimension = useStore((state) => state.displayDimension);
  const selectedTool = useStore((state) => state.selectedTool);
  const isCameraRotationLocked = useStore(
    (state) => state.isCameraRotationLocked
  );
  const setIsCameraRotationLocked = useStore(
    (state) => state.setIsCameraRotationLocked
  );
  const isCameraDollyLocked = useStore((state) => state.isCameraDollyLocked);
  const bounds = useStore((state) => state.bounds);
  const camRef = useRef<CameraControls>(null);
  const orthoRef = useRef<any>(null);
  const persRef = useRef<any>(null);
  const camLayer = new Layers();
  camLayer.enable(1);

  useEffect(() => {
    setTimeout(() => {
      if (camRef.current) {
        camRef.current.setLookAt(
          bounds.width / 2,
          15,
          bounds.height / 2,
          bounds.width / 2,
          0,
          bounds.height / 2,
          false
        );
        setIsCameraRotationLocked(isCameraRotationLocked);
      }
    }, 0);
  }, [displayDimension]);

  /*   useEffect(() => {
    if (
      camRef.current &&
      (selectedTool === "drawWall" || selectedTool === "select")
    ) {
      camRef.current.polarRotateSpeed = 0;
      camRef.current.azimuthRotateSpeed = 0;
    }else if(camRef.current && selectedTool==="move" && displayDimension=="2D"){
      camRef.current.polarRotateSpeed=0
      camRef.current.azimuthRotateSpeed=0.4
    }else if(camRef.current && selectedTool==="move" && displayDimension=="3D"){
      camRef.current.polarRotateSpeed=0
      camRef.current.azimuthRotateSpeed=0.4

    }
  }, [selectedTool]); */

  useEffect(() => {
    if (!camRef.current) return;

    if (isCameraRotationLocked) {
      camRef.current.polarRotateSpeed = 0;
      camRef.current.azimuthRotateSpeed = 0;
    } else {
      camRef.current.polarRotateSpeed = 1;
      camRef.current.azimuthRotateSpeed = 1;
    }
  }, [isCameraRotationLocked]);

  useEffect(() => {
    if (!camRef.current) return;

    if (isCameraRotationLocked) {
      camRef.current.dollySpeed = 0;
    } else {
      camRef.current.dollySpeed = 1;
    }
  }, [isCameraDollyLocked]);

  const cam = useMemo(() => <CameraControls ref={camRef} />, []);

  return (
    <group>
      {displayDimension === "2D" && (
        <group>
          <OrthographicCamera
            ref={orthoRef}
            makeDefault={displayDimension === "2D"}
            // position={[0, 10, 0]}
            layers={camLayer}
            zoom={60}
            far={5000}
            near={0.1}
          />
        </group>
      )}
      {displayDimension === "3D" && (
        <group>
          <PerspectiveCamera
            ref={persRef}
            makeDefault={displayDimension === "3D"}
            // position={[10, 10, 10]}
            layers={camLayer}
          />
        </group>
      )}
      {cam}
    </group>
  );
}

export default memo(Cameras);
