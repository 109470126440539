import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import MainRoutes from "./router/MainRoutes";

function App() {
  return (
    <BrowserRouter>
      <MantineProvider
        withGlobalStyles
        theme={{
          colorScheme: "dark",
          fontFamily:
            'ui-monospace, SFMono-Regular, Menlo, "Roboto Mono", monospace',
          headings: {
            fontFamily:
              'ui-monospace,SFMono-Regular,Menlo,"Roboto Mono",monospace',
          },
        }}
      >
        <Routes>
          <Route path="/*" element={<MainRoutes />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;
