/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

const Chair = require("../assets/models/chair_1.glb");
type GLTFResult = GLTF & {
  nodes: {
    ChairBack_LP_lambert1_0: THREE.Mesh;
  };
  materials: {
    ["lambert1.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(Chair) as unknown as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.ChairBack_LP_lambert1_0.geometry}
        material={materials["lambert1.001"]}
      />
    </group>
  );
}

useGLTF.preload(Chair);
