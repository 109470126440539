import { Html, TransformControls } from "@react-three/drei";
import { GroupProps, ThreeEvent, useFrame } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Euler,
  Group,
  MathUtils,
  Matrix4,
  Object3D,
  Quaternion,
  Vector3,
} from "three";
import useStore from "../app/store";
import ObjectToolButtons from "../components2D/ObjectToolButtons";

import Chair_1 from "../models/Chair_1";
import Chair_2 from "../models/Chair_2";
import Table_1 from "../models/Table_1";
import Table_2 from "../models/Table_2";
import Furniture from "./Furniture";

interface Props {
  lastFurniturePoint: Vector3 | null;
  mousePosition: React.MutableRefObject<Vector3>;
}

export default function Furnitures({
  lastFurniturePoint: lastWallPoint,
  mousePosition,
}: Props) {
  const [allFurnitures, setAllFurnitures] = useState<
    { transformation: Matrix4; id: string; type: string }[] | []
  >([]);

  const [objectTool, setObjectTool] = useState<
    "translate" | "rotate" | "scale"
  >("translate");

  const [selected, setSelected] = useState<Object3D>();

  const selectedFurnitureId = useStore((state) => state.selectedFurnitureId);
  const selectedTool = useStore((state) => state.selectedTool);
  const setIsCameraRotationLocked = useStore(
    (state) => state.setIsCameraRotationLocked
  );
  const setIsCameraDollyLocked = useStore(
    (state) => state.setIsCameraDollyLocked
  );
  const setSelectedTool = useStore((state) => state.setSelectedTool);

  const selectedToolRef = useRef(selectedTool);
  selectedToolRef.current = selectedTool;

  const furnitureRef = useRef<Group>(null);
  const idRef = useRef<number>(0);
  const toolRef = useRef<Group>(null);

  const models = {
    chair_1: Chair_1,
    chair_2: Chair_2,
    table_1: Table_1,
    table_2: Table_2,
  };

  function placeHolderMesh({ ...props }: GroupProps) {
    return (
      <group visible={false}>
        <mesh>
          <boxGeometry />
          <meshBasicMaterial />
        </mesh>
      </group>
    );
  }

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setSelectedTool("select");
      setIsCameraRotationLocked(false);
      setIsCameraDollyLocked(false);
      setSelected(undefined);
    }
  }, []);

  const rightClickFunction = useCallback((event: MouseEvent) => {
    if (event.button === 2) {
      setSelectedTool("select");
      setIsCameraDollyLocked(false);
      setIsCameraRotationLocked(false);
      setSelected(undefined);
    }
  }, []);

  const scrollFunction = useCallback(
    (event: WheelEvent) => {
      if (selectedToolRef.current !== "drawFurniture") return;

      if (event.deltaY > 0) {
        furnitureRef.current?.quaternion.multiply(
          new Quaternion().setFromEuler(
            new Euler(0, MathUtils.degToRad(30), 0),
            true
          )
        );
      } else {
        furnitureRef.current?.quaternion.multiply(
          new Quaternion().setFromEuler(
            new Euler(0, MathUtils.degToRad(-30), 0),
            true
          )
        );
      }
    },
    []
  );
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", rightClickFunction, false);
    document.addEventListener("wheel", scrollFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener(
        "mousedown",
        (e) => rightClickFunction(e),
        false
      );
      document.removeEventListener("wheel", scrollFunction, false);
    };
  }, []);

  function handleObjectToolClick(type: "translate" | "rotate" | "scale") {
    setObjectTool(type);
  }
  function handleDeleteClick() {
    if (!selected) return;
    const temp = [...allFurnitures];
    console.log(selected);

    const index = temp.findIndex((e) => e.id === selected.userData.id);
    temp.splice(index, 1);

    setSelected(undefined);
    setAllFurnitures(temp);
  }

  const controls = useMemo(
    () => (
      <TransformControls
        mode={objectTool}
        object={selected}
        onMouseDown={(e) => {
          setIsCameraRotationLocked(true);
        }}
        onMouseUp={() => {
          setIsCameraRotationLocked(false);
        }}
        rotationSnap={MathUtils.degToRad(30)}
      >
        <group ref={toolRef}>
          <Html style={{ transform: "translate(-11rem,5rem)" }}>
            <ObjectToolButtons
              handleObjectToolClick={handleObjectToolClick}
              handleDeleteClick={handleDeleteClick}
            />
          </Html>
        </group>
      </TransformControls>
    ),
    [selected, objectTool]
  );

  let ToolFurniture = placeHolderMesh;

  ToolFurniture = models[selectedFurnitureId as keyof typeof models];

  useEffect(() => {
    if (!lastWallPoint || !furnitureRef.current || !selectedFurnitureId) {
      return;
    }

    let temp = [...allFurnitures];
    setAllFurnitures([
      ...temp,
      {
        transformation: furnitureRef.current.matrix,
        id: idRef.current.toString(),
        type: selectedFurnitureId,
      },
    ]);
    idRef.current++;
  }, [lastWallPoint]);

  useEffect(() => {
    if (selectedTool !== "select") {
      setSelected(undefined);
    }
  }, [selectedTool]);

  useFrame(() => {
    if (selectedTool === "drawFurniture" && furnitureRef.current) {
      furnitureRef.current.position.copy(mousePosition.current);
    }
  });

  useFrame(() => {
    if (!toolRef.current || !selected) return;

    let toolPosition = new Vector3();
    selected.getWorldPosition(toolPosition);
    // console.log(toolPosition);

    toolRef.current.position.set(
      toolPosition.x,
      toolPosition.y,
      toolPosition.z
    );
  });

  const selectFurniture = (e: ThreeEvent<MouseEvent>) => {
    if (selectedTool === "select") {
      if (e.intersections[0].object.parent !== null) {
        setSelected(e.intersections[0].object.parent);
      }
    }
  };

  return (
    <group>
      <group ref={furnitureRef}>
        {selectedFurnitureId !== null && (
          <ToolFurniture visible={selectedTool === "drawFurniture"} />
        )}
      </group>
      {selected && controls}
      {allFurnitures.map(({ transformation, id, type }) => {
        return (
          <Furniture
            transformation={transformation}
            id={id}
            type={type}
            key={id}
            onClick={selectFurniture}
          />
        );
      })}
    </group>
  );
}
