import { Box, Button, Image } from "@mantine/core";
import useStore from "../app/store";
import Chair from "../assets/images/chair.png";
import Table from "../assets/images/table.png";

export default function FurnitureButtons() {
  const furnitureMode = useStore((state) => state.furnitureMode);
  const setFurnitureMode = useStore((state) => state.setFurnitureMode);
  const isFurnitureDrawerOpen = useStore(
    (state) => state.isFurnitureDrawerOpen
  );
  const setIsFurnitureDrawerOpen = useStore(
    (state) => state.setIsFurnitureDrawerOpen
  );

  function handleSelectChair() {
    setFurnitureMode("chair");
    setIsFurnitureDrawerOpen(true);
  }
  function handleSelectTable() {
    setFurnitureMode("table");
    setIsFurnitureDrawerOpen(true);
  }

  return (
    <Box
      sx={{
        position: "fixed",
        right: "10px",
        top: "40%",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleSelectChair()}
      >
        <Image src={Chair} width={40} />
      </Button>
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleSelectTable()}
      >
        <Image src={Table} width={40} />
      </Button>
    </Box>
  );
}
