import { FileWithPath } from "@mantine/dropzone";
import create from "zustand";

type Furnitures = "chair" | "table";
type Tools = "drawWall" | "drawFurniture" | "select" | null;
type Dimensions = "3D" | "2D" | null;
interface State {
  config: boolean;
  isFurnitureDrawerOpen: boolean;
  layoutImage: FileWithPath[];
  bounds: { width: number; height: number; wallHeight: number };
  selectedTool: Tools | null;
  displayDimension: Dimensions;
  isCameraRotationLocked: boolean;
  isCameraDollyLocked: boolean;
  furnitureMode: Furnitures;
  selectedFurnitureId: string | null;
  testLayoutImage: boolean;
  setLayoutImage: (input: FileWithPath[]) => void;
  setIsFurnitureDrawerOpen: (input: boolean) => void;
  setBounds: (input: {
    width: number;
    height: number;
    wallHeight: number;
  }) => void;
  setConfig: (input: boolean) => void;
  setSelectedTool: (input: Tools) => void;
  setDisplayDimension: (input: Dimensions) => void;
  setIsCameraRotationLocked: (input: boolean) => void;
  setIsCameraDollyLocked: (input: boolean) => void;
  setFurnitureMode: (input: Furnitures) => void;
  setSelectedFurnitureId: (input: string | null) => void;
  setTestLayoutImage: (input: boolean) => void;
}

const useStore = create<State>((set) => ({
  layoutImage: [],
  isFurnitureDrawerOpen: false,
  bounds: { width: 0, height: 0, wallHeight: 0 },
  config: false,
  selectedTool: "select",
  displayDimension: "2D",
  isCameraRotationLocked: false,
  isCameraDollyLocked: false,
  furnitureMode: "chair",
  selectedFurnitureId: null,
  testLayoutImage: false,
  setLayoutImage: (input) => {
    set((state) => {
      return { layoutImage: input };
    });
  },
  setBounds: (input) => {
    set((state) => {
      return { bounds: input };
    });
  },
  setConfig: (input) => {
    set((state) => {
      return { config: true };
    });
  },
  setSelectedTool: (input) => {
    set({
      selectedTool: input,
    });
  },
  setDisplayDimension: (input) => {
    set((state) => {
      return { displayDimension: input };
    });
  },
  setIsCameraRotationLocked: (input) => {
    set({ isCameraRotationLocked: input });
  },
  setIsCameraDollyLocked: (input) => {
    set({ isCameraDollyLocked: input });
  },
  setIsFurnitureDrawerOpen: (input) => {
    set({ isFurnitureDrawerOpen: input });
  },
  setFurnitureMode: (input) => {
    set({ furnitureMode: input });
  },
  setSelectedFurnitureId: (input) => {
    set({ selectedFurnitureId: input });
  },
  setTestLayoutImage: (input) => {
    set({ testLayoutImage: input });
  },
}));

export default useStore;
