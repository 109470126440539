import { Box, Button, Image } from "@mantine/core";
import MoveIcon from "../assets/images/move.png";
import RotateIcon from "../assets/images/rotate.png";
import ScaleIcon from "../assets/images/scale.png";
import DeleteIcon from "../assets/images/delete.png";

interface Props {
  handleObjectToolClick: (type: "translate" | "rotate" | "scale") => void;
  handleDeleteClick: () => void;
}

export default function ObjectToolButtons({
  handleObjectToolClick,
  handleDeleteClick,
}: Props) {
  return (
    <Box
      sx={{
        position: "fixed",
        left: "10px",
        top: "40%",
        display: "flex",
        flexDirection: "row",
        padding: "5px",
      }}
    >
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleObjectToolClick("translate")}
      >
        <Image src={MoveIcon} width={25} />
      </Button>
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleObjectToolClick("rotate")}
      >
        <Image src={RotateIcon} width={25} />
      </Button>
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleObjectToolClick("scale")}
      >
        <Image src={ScaleIcon} width={25} />
      </Button>
      <Button
        size="md"
        color={"gray"}
        sx={{ margin: "5px" }}
        onClick={() => handleDeleteClick()}
      >
        <Image src={DeleteIcon} width={25} />
      </Button>
    </Box>
  );
}
