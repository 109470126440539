/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

const Table = require("../assets/models/table_2.glb");

type GLTFResult = GLTF & {
  nodes: {
    Object_15001: THREE.Mesh;
    Object_15001_1: THREE.Mesh;
    Object_15001_2: THREE.Mesh;
    Object_15001_3: THREE.Mesh;
    Object_15001_4: THREE.Mesh;
  };
  materials: {
    ["wood.002"]: THREE.MeshPhysicalMaterial;
    ["Metal.001"]: THREE.MeshStandardMaterial;
    ["Plastic010.002"]: THREE.MeshStandardMaterial;
    ["Plastic_Rubber_Matt.001"]: THREE.MeshStandardMaterial;
    ["Plastic010.003"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(Table) as unknown as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_15001.geometry}
        material={materials["wood.002"]}
      />
      <mesh
        geometry={nodes.Object_15001_1.geometry}
        material={materials["Metal.001"]}
      />
      <mesh
        geometry={nodes.Object_15001_2.geometry}
        material={materials["Plastic010.002"]}
      />
      <mesh
        geometry={nodes.Object_15001_3.geometry}
        material={materials["Plastic_Rubber_Matt.001"]}
      />
      <mesh
        geometry={nodes.Object_15001_4.geometry}
        material={materials["Plastic010.003"]}
      />
    </group>
  );
}

useGLTF.preload(Table);
