import {
  BufferGeometry,
  CanvasTexture,
  Material,
  MathUtils,
  Matrix4,
  Mesh,
  Quaternion,
  RepeatWrapping,
  Shape,
  Texture,
  TextureLoader,
  Vector3,
} from "three";
import useStore from "../app/store";
import { useTexture } from "@react-three/drei";
import {
  memo,
  MutableRefObject,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import testImage from "../assets/images/wipelot.jpg";
import { ThreeEvent, useFrame } from "@react-three/fiber";

interface Props {
  handleClickFloor: (event: ThreeEvent<MouseEvent>) => void;
  handlePointerMove: (event: ThreeEvent<PointerEvent>) => void;
}

function Floor({
  handleClickFloor: handleClick,
  handlePointerMove: handleMove,
}: Props) {
  const bounds = useStore((state) => state.bounds);
  const layoutImage = useStore((state) => state.layoutImage);
  const testlayoutImage = useStore((state) => state.testLayoutImage);
  let texture;
  if (testlayoutImage) {
    texture = new TextureLoader().load(testImage);
  } else {
    const imageUrl = URL.createObjectURL(layoutImage[0]);
    texture = new TextureLoader().load(imageUrl);
  }
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;

  return (
    <group>
      <mesh
        rotation={[-MathUtils.degToRad(90), 0, 0]}
        position={[bounds.width / 2, 0, bounds.height / 2]}
        onClick={(e) => handleClick(e)}
        onPointerMove={(e) => handleMove(e)}
      >
        <planeBufferGeometry args={[bounds.width, bounds.height, 100, 100]} />
        <meshStandardMaterial map={texture} />
      </mesh>
    </group>
  );
}

export default memo(Floor);
