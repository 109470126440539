/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

const Table = require("../assets/models/table_1.glb");

type GLTFResult = GLTF & {
  nodes: {
    Object_0002: THREE.Mesh
    Object_0002_1: THREE.Mesh
    Object_0002_2: THREE.Mesh
  }
  materials: {
    ['material1.001']: THREE.MeshStandardMaterial
    ['material2.001']: THREE.MeshStandardMaterial
    ['material3.001']: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials } = useGLTF(Table) as unknown as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Object_0002.geometry} material={materials['material1.001']} />
      <mesh geometry={nodes.Object_0002_1.geometry} material={materials['material2.001']} />
      <mesh geometry={nodes.Object_0002_2.geometry} material={materials['material3.001']} />
    </group>
  )
}

useGLTF.preload(Table)
