/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

const Chair = require("../assets/models/chair_2.glb");
type GLTFResult = GLTF & {
  nodes: {
    Box02_Chairback_0_1: THREE.Mesh;
    Box02_Chairback_0_2: THREE.Mesh;
    Box02_Chairback_0_3: THREE.Mesh;
    Box02_Chairback_0_4: THREE.Mesh;
    Box02_Chairback_0_5: THREE.Mesh;
  };
  materials: {
    Chairback: THREE.MeshStandardMaterial;
    Matteplastic: THREE.MeshStandardMaterial;
    BlackMetal: THREE.MeshStandardMaterial;
    Lether: THREE.MeshStandardMaterial;
    Chrome: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(Chair) as unknown as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Box02_Chairback_0_1.geometry}
        material={materials.Chairback}
      />
      <mesh
        geometry={nodes.Box02_Chairback_0_2.geometry}
        material={materials.Matteplastic}
      />
      <mesh
        geometry={nodes.Box02_Chairback_0_3.geometry}
        material={materials.BlackMetal}
      />
      <mesh
        geometry={nodes.Box02_Chairback_0_4.geometry}
        material={materials.Lether}
      />
      <mesh
        geometry={nodes.Box02_Chairback_0_5.geometry}
        material={materials.Chrome}
      />
    </group>
  );
}
useGLTF.preload(Chair);
