import {
  Box,
  TextInput,
  Group,
  Text,
  Button,
  NumberInput,
} from "@mantine/core";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../app/store";

import testImage from "../assets/images/wipelot.jpg";

export default function Config() {
  const { setLayoutImage, setBounds, setConfig,setTestLayoutImage } = useStore();
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
  const [file, setFile] = useState<FileWithPath[]>([]);

  const navigate = useNavigate();

  const handleSubmit = (e: {
    width: number;
    height: number;
    wallHeight: number;
  }) => {
    setLayoutImage(file);
    setBounds({ width: e.width, height: e.height, wallHeight: e.wallHeight });
    setConfig(true);
    navigate("/editor");
  };

  //
  // useEffect(() => {
  //   setBounds({ width: 26, height: 12, wallHeight: 2.5 });
  //   setConfig(true);
  //   navigate("/editor");
  // }, []);

  const form = useForm({
    initialValues: {
      width: 26,
      height: 12,
      wallHeight: 2.5,
    },
  });

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <form
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <Box sx={{ alignContent: "flex-start", display: "flex" }}>
            <NumberInput
              label="Width"
              sx={{ maxWidth: 300, padding: "10px" }}
              size="xs"
              //   value={width}
              //   type="number"
              //   onChange={(event) => setWidth(event.target.value)}
              {...form.getInputProps("width")}
            />
            <NumberInput
              label="Height"
              sx={{ maxWidth: 300, padding: "10px" }}
              size="xs"
              //   value={height}
              //   type="number"
              //   onChange={(event) => setHeight(event.target.value)}
              {...form.getInputProps("height")}
            />
            <NumberInput
              label="Wall Height"
              sx={{ maxWidth: 300, padding: "10px" }}
              size="xs"
              //   value={height}
              //   type="number"
              //   onChange={(event) => setHeight(event.target.value)}
              {...form.getInputProps("wallHeight")}
            />
          </Box>
          <Dropzone
            sx={{ maxWidth: 600, padding: "10px", margin: "10px" }}
            onDrop={(files) => {
              setIsImageUploaded(true);
              setFile(files);
            }}
            onReject={(files) => console.log("rejected files", files)}
            maxSize={3 * 1024 ** 2}
            disabled={isImageUploaded}
            accept={IMAGE_MIME_TYPE}
            multiple={false}
          >
            {!isImageUploaded ? (
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: 220, pointerEvents: "none" }}
              >
                <div>
                  <Text size="xl" inline>
                    Upload Floor Plan
                  </Text>
                </div>
              </Group>
            ) : (
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: 220, pointerEvents: "none" }}
              >
                <div>
                  <Text size="xl" inline>
                    Floor plan uploaded
                  </Text>
                </div>
              </Group>
            )}
          </Dropzone>
          <Button sx={{ padding: "10px", margin: "10px" }} type="submit">
            Start Editing
          </Button>
          <Text
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Or
          </Text>
          <Button
            sx={{ padding: "10px", margin: "10px" }}
            onClick={() => {
              setTestLayoutImage(true);
              setBounds({ width: 26, height: 12, wallHeight: 2.5 });
              setConfig(true);
              navigate("/editor");
            }}
          >
            Continue with demo plan
          </Button>
        </form>
      </Box>
    </div>
  );
}
