import { Box, Drawer, ScrollArea } from "@mantine/core";
import useStore from "../app/store";
import ChairList from "../constants/Chairs.json";
import TableList from "../constants/Tables.json";
import FurnitureCard from "./FurnitureCard";

export default function FurnitureDrawer() {
  const isFurnitureDrawerOpen = useStore(
    (state) => state.isFurnitureDrawerOpen
  );
  const furnitureMode = useStore((state) => state.furnitureMode);
  const setIsFurnitureDrawerOpen = useStore(
    (state) => state.setIsFurnitureDrawerOpen
  );
  function handleDrawerClose() {
    setIsFurnitureDrawerOpen(false);
  }
  return (
    <Drawer
      opened={isFurnitureDrawerOpen}
      position="right"
      onClose={handleDrawerClose}
      sx={{ overflow: "visible", "drawer": {display:"flex"} }}
      styles={{"drawer": {display:"flex", flexDirection:"column"}}}
    >
      
      <ScrollArea
        type="hover"
        sx={{
          flexGrow:1,
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
        styles={{root: {flexGrow:1}}}
      >
        {furnitureMode === "chair" &&
          ChairList.map(({ id, name }) => (
            <FurnitureCard key={id} id={id} name={name} type={furnitureMode} />
          ))}
        {furnitureMode === "table" &&
          TableList.map(({ id, name }) => (
            <FurnitureCard key={id} id={id} name={name} type={furnitureMode} />
          ))}
      </ScrollArea>
      
    </Drawer>
  );
}
